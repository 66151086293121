// libraries
import { useSnackbar } from "notistack";

//react
import { useEffect, useState, useRef, useCallback } from "react";

// material UI components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// custom components
import TableComponent from "../components/Table";
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
// utils
import {
  axiosConfig,
  httpErrorHandler,
  formatDateTimeFns,
} from "../utils/helpers";
import SearchHeader from "../components/search_header/SearchHeader";
import CustomPagination from "../components/CustomPagination";
//hooks
import useAxios from "../hooks/useAxios";
const filterByMenu = [
  { label: "Name", value: "first_name" },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Service",
    value: "service_name",
  },
];
const cols = [
  {
    name: "NAME",
    accessor: "user",
    getAccessor: (el) => el.first_name + " " + el.last_name,
  },
  {
    name: "CREDIT USED",
    accessor: "credit_used",
  },
  {
    name: "CREATED ON",
    accessor: "root",
    getAccessor: (row) =>
      row?.created_on ? formatDateTimeFns(row?.created_on) : "-",
  },
  {
    name: "TOTAL TIME",
    accessor: "root",
    getAccessor: (el) => {
      return el?.total_time
        ? `${el?.total_time?.split(":")[1]}:${
            el?.total_time?.split(":")[2]?.split(".")[0]
          } Mins`
        : "-";
    },
  },
  {
    name: "SERVICE USED",
    accessor: "root",
    getAccessor: (el) => {
      return el?.service ? el.service.toUpperCase() : "-";
    },
  },
];

const inititalState = {
  clients: [],
  calls: {
    count: 0,
    results: [],
  },
  credits: [],
};

//return true if all values in search key are null
const checkSearchKey = (searchKey) => {
  return Object.values(searchKey).every((value) => {
    if (value === null) {
      return true;
    }
    return false;
  });
};

function CallHistories({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(inititalState);
  const [error, setError] = useState(null);
  const paginationCall = useRef(false);
  const [searchKey, setSearchKey] = useState({
    user_first_name: null,
    user_email: null,
    service_name: null,
    created_on_from: null,
    created_on_to: null,
  });
  const [reload, setReload] = useState(null);
  const [searching, setSearching] = useState(false);
  //for local filtering
  const [page, setPage] = useState(1);

  const [loadingData, setLoadingData] = useState(true);

  const PAGE_SIZE = 16;
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  // const abortController = useMemo(() => new AbortController(), []);

  const fetchCalls = useCallback(
    async ({ abortController, searchParams, pageValue = null }) => {
      setError(null);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/calls/`,
          params: {
            page: pageValue ? pageValue : page,
            page_size: PAGE_SIZE,

            ordering: "-created_on",
            ...searchParams,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setData({
          calls: {
            count: response.data.count,
            results: response.data.results,
          },
        });
        paginationCall.current = false;
        return null;
      } catch (error) {
        if (error.message !== "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        paginationCall.current = false;
        return error;
      }
    },
    [closeSnackbar, enqueueSnackbar, page]
  );
  //for initial fetching of the data
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      if (checkSearchKey(searchKey) && !paginationCall.current) {
        setLoadingData(true);
        const error = await fetchCalls({
          abortController,
          searchParams: searchKey,
        });
        if (!error || (error && error?.message !== "canceled")) {
          setLoadingData(false);
        }
      }
    })();

    return () => abortController.abort(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, searchKey, closeSnackbar, enqueueSnackbar, fetchCalls]); //don't add page dependency

  //handling of search and pagination
  useEffect(() => {
    const abortController = new AbortController();
    //search call
    (async () => {
      if (!checkSearchKey(searchKey) && !paginationCall.current) {
        setPage(1);
        setSearching(true);
        const error = await fetchCalls({
          abortController,
          searchParams: searchKey,
          pageValue: 1,
        });
        if (!error || (error && error?.message !== "canceled")) {
          setSearching(false);
        }
      } else if (paginationCall.current && checkSearchKey(searchKey)) {
        //pagination calls
        setSearching(true);
        const error = await fetchCalls({
          abortController,
          searchParams: searchKey,
          pageValue: page,
        });
        if (!error || (error && error?.message !== "canceled"))
          setSearching(false);
      } else if (paginationCall.current || !checkSearchKey(searchKey)) {
        //searchKey is not null this Will do search also for the given key with and pagination on it.
        setSearching(true);
        const error = fetchCalls({
          abortController,
          searchParams: searchKey,
          pageValue: page,
        });
        if (!error || (error && error?.message !== "canceled"))
          setSearching(false);
      }
    })();

    return () => abortController.abort();
  }, [searchKey, reload, page, enqueueSnackbar, closeSnackbar, fetchCalls]);

  return (
    <>
      {loadingData || error ? (
        <ComponentLoader
          minHeight="calc(100vh - 120px)"
          loading={loadingData}
          error={error}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
          sx={{ background: "transparent" }}
        />
      ) : (
        <Grid container rowSpacing={5} sx={{ mb: "2.5rem" }}>
          {/* START: Up Side */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid container>
                <Grid item xs={12}>
                  <SearchHeader
                    disableAddButton={true}
                    searchBarPlaceholder="Search by Name, Email, Service"
                    setSearchKey={setSearchKey}
                    showDateFilter={true}
                    filterByMenu={filterByMenu}
                    standardUsersPage={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {searching || error ? (
              <ComponentLoader
                loading={searching}
                error={error}
                sx={{ background: "transparent" }}
                retry={() => {
                  setReload((prev) => {
                    return !prev;
                  });
                }}
              />
            ) : (
              <Paper elevation={0}>
                <TableComponent
                  cols={cols}
                  // rows={data.calls}
                  loading={searching || loadingData}
                  // paging="true"
                  rows={{
                    count: data?.calls?.results?.length,
                    results: data?.calls?.results,
                  }}
                  rowLink={{
                    linkKey: "id",
                    linkTo: "/call",
                  }}
                  tableRowStyles={{
                    backgroundColor: "#fff",
                    transition: "background-color .2s ease",
                    ":hover": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Paper>
            )}

            <CustomPagination
              disabled={searching || loadingData}
              last_page_no={Math.ceil(data.calls.count / PAGE_SIZE)}
              limit={data.calls.results.length}
              handlePaginationChange={(_, value) => {
                setPage(value);
                paginationCall.current = true;
              }}
            />
          </Grid>
          {/* END: Up Side */}
        </Grid>
      )}
    </>
  );
}

export default AuthHOC(CallHistories);
