import { Box, Button, Grid, Stack, Typography } from "@mui/material";
//assets
import errorImg from "../../assets/error/security.svg";
import nbLogo from "../../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
//utils and helper
import errorMessages from "../../utils/errorMessages.json";
//3rd party
import { useHistory } from "react-router-dom";
//context
import { globalContext } from "../../context/globalContext";
//react
import { useRef, useContext } from "react";

function Error4xx({ status }) {
  const history = useHistory();
  const globalCtx = useRef();
  globalCtx.current = useContext(globalContext);

  const CustomForm = () => (
    <>
      <Stack
        direction="column"
        spacing={1}
        py={5}
        style={{
          width: "100%",
          maxWidth: "700px",
        }}
      >
        <Typography
          sx={{
            fontSize: "clamp(34px , 4vw, 64px)",
            fontWeight: "700",
            lineHeight: "83.33px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#DB9D64",
          }}
        >
          {errorMessages[status]
            ? errorMessages[status].title
            : `Error ${status ? status : 404}`}
        </Typography>
        <Stack
          direction="column"
          py={2}
          style={{
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Typography
            style={{
              fontSize: "clamp(34px , 4vw, 64px)",
              fontWeight: "700",
              lineHeight: "62.5px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#000000",
              width: "100%",
            }}
          >
            {errorMessages[status]
              ? errorMessages[status].message
              : status
              ? "Client Error"
              : "Ooops! Page not found "}
          </Typography>

          <Typography
            sx={{
              // fontSize: "clamp(1.5rem , 1rem + 5vw ,32px)",
              fontSize: "clamp(1.5rem , 1rem + 1vw, 24px)",
              fontWeight: "500",
              lineHeight: "41.66px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#959595",
              width: "100%",
            }}
          >
            {errorMessages[status]
              ? errorMessages[status].detail
              : status
              ? ""
              : "This page doesn’t exist or has been removed."}
            {/* This page doesn’t exist or has been removed. */}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          py={2}
          style={{
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Button
            disableElevation
            size="small"
            variant="contained"
            sx={{
              maxWidth: "250px",
              textTransform: "none",
              fontSize: "28px",
              fontWeight: "500",
              backgroundColor: "#2F7EC7",
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </>
  );

  return (
    <Grid
      container
      style={{
        height: "100vh",
        backgroundColor: "#F5F6FA",
      }}
    >
      <Grid item xs={12} lg={6}>
        <Stack
          direction="column"
          style={{
            // width: "100%",
            // maxWidth: "700px",
            alignItems: "flex-start",
            display: "flex",
            justifyContent: "center",
            padding: "30px 0 0 30px",
          }}
        >
          <img src={nbLogo} alt="Trial" height={69} width={123} />
        </Stack>
        <Box
          py={7}
          style={{
            height: "70vh",
            backgroundColor: "#F5F6FA",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "32px",
          }}
        >
          <CustomForm />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <img src={errorImg} alt="Trial" width="100%" />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Error4xx;
