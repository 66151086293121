//react
import React, { useEffect, useRef, useState } from "react";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function InnerText({ text, ...props }) {
  const textRef = useRef();

  return (
    <Typography
      component="span"
      ref={textRef}
      sx={{
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: "500",
        backgroundColor: "#EBEBEB",
        color: "#8A8A8A",
        py: "8px",
        px: "16px",
        borderRadius: "4px",
        mr: "4px",
        mb: "4px",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
      }}
    >
      {text}
    </Typography>
  );
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "none",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    fontSize: theme.typography.pxToRem(12),
    border: "2px solid #eaeaea",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
    "::before": {
      border: "2px solid #eaeaea",
    },
  },
}));
//custom tooltip component
const CustomTooltipComponent = ({ services, ...props }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        py: "16px",
        px: "8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {services?.length ? (
        services?.map((service, index) => {
          return <InnerText key={`inner-text-${index}`} text={service?.name} />;
        })
      ) : (
        <InnerText key={`inner-text-${0}`} text="No Service Found" />
      )}
    </Paper>
  );
};

function CustomServicesCell({ data, ...props }) {
  const [services, setServices] = useState([]);
  useEffect(() => {
    if (data?.services) {
      setServices(data?.services);
    }
  }, [data]);

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: "180px",
        backgroundColor: "transparent",
        maskImage: "linear-gradient(90deg, #000 80%, transparent)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        minHeight: "30px",

        ...props.sx,
      }}
    >
      <CustomTooltip
        title={<CustomTooltipComponent services={services} />}
        placement="right"
        arrow
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {services?.length ? (
            services?.map((service, index) => {
              return (
                <InnerText key={`inner-text-${index}`} text={service?.name} />
              );
            })
          ) : (
            <InnerText key={`inner-text-${0}`} text={"Not Found"} />
          )}
        </Box>
      </CustomTooltip>
    </Paper>
  );
}

export default CustomServicesCell;
