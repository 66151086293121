import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
//hooks
import AuthHoc from "../hoc/AuthHOC";
// custom components
import TableComponent from "../components/Table";
// utils
import urls from "../utils/urls.json";
import { Button, Dialog, Stack, Box } from "@mui/material";
import EditCreditRequestForm from "../components/forms/editRequestForm";
import CancelRequestForm from "../components/forms/cancelRequestForm";

const cols = [
  {
    name: "REQUEST ID",
    accessor: "id",
  },
  {
    name: "CREDITS ADDED",
    accessor: "credit",
    getAccessor: (el) => el.added,
  },
  {
    name: "CREDITS USED",
    accessor: "credit",
    getAccessor: (el) => el.used,
  },

  {
    name: "CREDITS AVAILABLE",
    accessor: "root",
    getAccessor: (el) => {
      return el?.credit?.added - el?.credit?.used;
    },
  },
  {
    name: "ISSUED ON",
    accessor: "credit",
    getAccessor: (el) => el?.added_on,
  },
  {
    name: "VALID TILL",
    accessor: "credit",
    getAccessor: (el) => el?.validity,
  },
  {
    name: "STATUS",
    accessor: "status",
  },
];

function CreditRequestsDetails({ user }) {
  const location = useLocation();
  const paymentCompleted =
    location.state.credit.payment.status === "incomplete";
  const breadcrumbs = [
    <Link key="1" to={urls.credits}>
      Credits
    </Link>,
    <Link key="2" to={urls.credit_requests}>
      Manage Credits
    </Link>,
    <Typography key="3" color="text.primary">
      Credits Request Details
    </Typography>,
  ];
  const [open, setOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickCancelOpen = () => {
    setCancelOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseCancelOpen = () => {
    setCancelOpen(false);
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* START: Breadcrumb */}
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>{" "}
        {/* END: Breadcrumb */}
        {/* START: Body Row */}
        <Grid item xs={12}>
          <Grid style={{ marginTop: "54px" }} item xs={12}>
            <TableComponent
              cols={cols}
              rows={{ count: 1, results: [location.state] }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={5}>
            {location.state.status === "ACCEPTED" &&
              location.state.credit.payment.status === "incomplete" && (
                <Paper elevation={0} sx={{ padding: "24px" }}>
                  <Stack spacing={5}>
                    <Stack
                      spacing={1}
                      direction="column"
                      borderBottom="1px solid #EAEAEA"
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#0F0F0F",
                        }}
                      >
                        Payment Details
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#898989",
                        }}
                      >
                        View supervisor and organisation details here{" "}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={5}>
                      <Stack direction="row" spacing={5}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Mode of Payment
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#0F0F0F",
                          }}
                        >
                          {location.state.credit.payment.mode === "STRIPE" &&
                            "Stripe"}
                          {location.state.credit.payment.mode === "CHEQUE" &&
                            "Cheque"}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={7}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Payment Status
                        </Typography>

                        <Box
                          sx={{
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px 16px 4px 16px",
                            color: paymentCompleted ? "#E03A3A" : "#10A44B",
                            backgroundColor: paymentCompleted
                              ? "#FFF3F3"
                              : "#E0FFDF",
                          }}
                        >
                          <Typography component="span">
                            {paymentCompleted ? "Not Done" : "Completed"}
                          </Typography>
                        </Box>
                      </Stack>
                      {location.state.credit.payment.mode === "STRIPE" && (
                        <Button
                          variant="contained"
                          onClick={handleClickOpen}
                          sx={{
                            backgroundColor: "#2F7EC7",
                            float: "right",
                            clear: "right",
                            padding: "16px 24px 16px 24px",
                            maxWidth: "250px",
                            ":hover": {
                              background: "#2974ba",
                            },
                          }}
                          size="small"
                          disableElevation
                          component={Link}
                          to={`/credits/requests/checkout/${location.state.id}`}
                        >
                          Proceed to checkout
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Paper>
              )}

            {location.state.status === "COMPLETED" &&
              location.state.credit.payment.status === "completed" && (
                <Paper elevation={0} sx={{ padding: "24px" }}>
                  <Stack spacing={5}>
                    <Stack
                      spacing={1}
                      direction="column"
                      borderBottom="1px solid #EAEAEA"
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#0F0F0F",
                        }}
                      >
                        Payment Details
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#898989",
                        }}
                      >
                        View supervisor and organisation details here{" "}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={5}>
                      <Stack direction="row" spacing={5}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Mode of Payment
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#0F0F0F",
                          }}
                        >
                          {location.state.credit.payment.mode === "STRIPE" &&
                            "Stripe"}
                          {location.state.credit.payment.mode === "CHEQUE" &&
                            "Cheque"}{" "}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={7}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Payment Status
                        </Typography>
                        <Box
                          sx={{
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px 16px 4px 16px",
                            color: paymentCompleted ? "#E03A3A" : "#10A44B",
                            backgroundColor: paymentCompleted
                              ? "#FFF3F3"
                              : "#E0FFDF",
                          }}
                        >
                          <Typography component="span">
                            {paymentCompleted ? "Not Done" : "Completed"}
                          </Typography>
                        </Box>
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
              )}

            {location.state.status === "SENT" &&
              location.state.credit.payment.status === "incomplete" && (
                <Paper elevation={0} sx={{ padding: "24px" }}>
                  <Stack spacing={5}>
                    <Stack
                      spacing={1}
                      direction="column"
                      borderBottom="1px solid #EAEAEA"
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#0F0F0F",
                        }}
                      >
                        Payment Details
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#898989",
                        }}
                      >
                        View supervisor and organisation details here{" "}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={5}>
                      <Stack direction="row" spacing={5}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Mode of Payment
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#0F0F0F",
                          }}
                        >
                          {location.state.credit.payment.mode === "STRIPE" &&
                            "Stripe"}
                          {location.state.credit.payment.mode === "CHEQUE" &&
                            "Cheque"}{" "}
                        </Typography>
                      </Stack>

                      <Stack direction="row" spacing={7}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Payment Status
                        </Typography>
                        <Box
                          sx={{
                            height: "30px",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "4px 16px 4px 16px",
                            color: paymentCompleted ? "#E03A3A" : "#10A44B",
                            backgroundColor: paymentCompleted
                              ? "#FFF3F3"
                              : "#E0FFDF",
                          }}
                        >
                          <Typography component="span">
                            {paymentCompleted ? "Not Done" : "Completed"}
                          </Typography>
                        </Box>
                      </Stack>

                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#898989",
                        }}
                      >
                        NOTE: You will be able to continue with payment once the
                        request is approved by the manager.{" "}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              )}

            {location.state.status === "SENT" && (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  onClick={handleClickOpen}
                  sx={{
                    backgroundColor: "#2F7EC7",
                    float: "right",
                    clear: "right",
                    padding: "16px 24px 16px 24px",
                  }}
                  size="small"
                  disableElevation
                >
                  Edit Request
                </Button>

                <Dialog onClose={handleClose} open={open} fullWidth>
                  <EditCreditRequestForm
                    handleClose={handleClose}
                    location={location}
                  />
                </Dialog>
                <Button
                  variant="outlined"
                  onClick={handleClickCancelOpen}
                  sx={{
                    color: "#939393",
                    border: "1px solid #8A8A8A",
                    padding: "16px 24px 16px 24px",
                  }}
                  disableElevation
                >
                  Cancel Request
                </Button>
                <Dialog
                  onClose={handleCloseCancelOpen}
                  open={cancelOpen}
                  fullWidth
                >
                  <CancelRequestForm
                    handleCloseCancelOpen={handleCloseCancelOpen}
                    creditrequest_id={location.state.id}
                  />
                </Dialog>
              </Stack>
            )}

            {/* {location.state.status !== "ACCEPTED" &&
              location.state.status !== "REJECTED" && (
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={handleClickCancelOpen}
                    sx={{
                      color: "#939393",
                      border: "1px solid #8A8A8A",
                      padding: "16px 24px 16px 24px",
                    }}
                    disableElevation
                  >
                    Cancel Request
                  </Button>
                  <Dialog
                    onClose={handleCloseCancelOpen}
                    open={cancelOpen}
                    fullWidth
                  >
                    <CancelRequestForm
                      handleCloseCancelOpen={handleCloseCancelOpen}
                      creditrequest_id={location.state.id}
                    />
                  </Dialog>
                </Stack>
              )} */}
            {location.state.status === "ACCEPTED" &&
              location.state?.credit?.payment?.mode === "CHEQUE" && (
                <Paper elevation={0} sx={{ padding: "16px" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#959595",
                      mb: "4px",
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "28px",
                      lineHeight: "36px",
                      color: "#2f7ec7",
                      mb: "8px",
                    }}
                  >
                    $
                    {parseFloat(
                      location.state?.credit?.payment?.price / 100
                    ).toFixed(3)}
                    /-
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#959595",
                    }}
                  >
                    Please complete the payment to activate the credits. Once
                    the payment is completed the credits will be activated and
                    ready to use.
                  </Typography>
                </Paper>
              )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthHoc(CreditRequestsDetails);
