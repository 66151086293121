import { Paper, Container, Stack, Typography, Button } from "@mui/material";
import logo from "../assets/signin/logo.svg";
import success from "../assets/password/vector.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function PasswordResetSuccessfull({ ...props }) {
  const history = useHistory();
  const [counter, setCounter] = useState(10);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((value) => {
        if (value !== 0) return value - 1;
        return 0;
      });
    }, 1000);
    const timeoutId = setTimeout(() => {
      history.push("/accounts/signin");
    }, 10000);
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [history]);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px 140px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={8}>
              <Stack justifyContent="center" alignItems="center">
                <img src={logo} alt="logo" width={108} height={60} />
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <img src={success} alt="done" width={100} height={100} />
              </Stack>
            </Stack>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: "500",
                color: "#0F0F0F",
                textAlign: "center",
                lineHeight: "0.1em",
                width: "100%",
              }}
            >
              Password Reset Successfull
            </Typography>
            <Stack direction="column" spacing={4}>
              <Stack justifyContent="center" alignItems="center">
                <Stack direction="column">
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#8A8A8A",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Password Reset Successfull
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "400",
                      color: "#8A8A8A",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    You will be redirected to the login page in {counter}{" "}
                    seconds.
                  </Typography>
                </Stack>
              </Stack>
              <Stack justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#2F7EC7",
                    padding: "10px",
                    borderRadius: "5px",
                    width: "40%",
                  }}
                  href="/accounts/signin"
                >
                  LOGIN
                </Button>
              </Stack>
            </Stack>

            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                  textAlign: "center",
                  lineHeight: "0.1em",
                  width: "100%",
                  marginTop: "72px",
                }}
              >
                If you need any help, contact{" "}
                <font color="#2F7EC7">Neurobit PSG Support</font>
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
