// libraries
import { useEffect, useState, useCallback, useRef } from "react";

// material UI components
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import CreditConsumed from "../components/info-card/CreditConsumed";
import Chart from "../components/Chart";
import Requests from "../components/info-card/Requests";
import AuthHOC from "../hoc/AuthHOC";
import ComponentLoader from "../components/loader/ComponentLoader";
import { useSnackbar } from "notistack";
// utils
import urls from "../utils/urls.json";
import {
  axiosConfig,
  formatDateTimeFns,
  httpErrorHandler,
} from "../utils/helpers";
//hooks
import useAxios from "../hooks/useAxios";
import TableComponent from "../components/Table";
const cols = [
  {
    name: "CREDITS ADDED",
    accessor: "added",
  },
  {
    name: "CREDITS AVAILABLE",
    accessor: "available",
  },
  {
    name: "CREDITS USED",
    accessor: "used",
  },
  {
    name: "ISSUED ON",
    accessor: "root",
    getAccessor: (row) => formatDateTimeFns(row.added_on),
  },
  {
    name: "VALIDITY TILL",
    accessor: "root",
    getAccessor: (row) => formatDateTimeFns(row.validity),
  },
  {
    name: "STATUS",
    accessor: "active",
  },
];

const createDate = (datestring) => {
  const options = {
    day: "numeric",
    month: "short",
  };

  const date = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datestring)
  );
  return date;
};
function createGraphData(data) {
  const graphData = data.map((element, index) => {
    createDate(element.created_on__date);
    return {
      date: createDate(element.created_on__date),
      credits: element.credits_used,
    };
  });
  return graphData;
}

function Credits({ user }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [chartData, setChartData] = useState([]);
  const [fetchingChartData, setFetchingChartData] = useState(true);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);

  const [fetchingCredits, setFetchingCredits] = useState(true);
  const [creditRequestSummary, setCreditRequestSummary] = useState(null);

  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  //fetch credits callback
  const fetchCredits = useCallback(
    async (abortController) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/`,
          params: {
            ordering: "-added_on",
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        return response;
      } catch (error) {
        if (error.message !== "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [closeSnackbar, enqueueSnackbar]
  );

  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/request/summary`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setCreditRequestSummary(response.data);
      } catch (error) {
        if (error && error.message !== "canceled")
          httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
  }, [enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    const abortController = new AbortController();
    let isMounted = true;
    setFetchingCredits(true);
    fetchCredits(abortController)
      .then((response) => {
        if (isMounted && response) {
          setFetchingCredits(false);
          setData(response.data);
        }
      })
      .catch((error) => {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
        setFetchingCredits(false);
      });
    return () => {
      abortController.abort();
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCredits]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/usage-history-overview`,
          params: { operation: "get-basic-info" },
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        // if (isMounted) {
        setChartData((prevState) => {
          return { ...prevState, credits: createGraphData(response.data) };
        });
        setFetchingChartData(false);
        setLoading(false);
        // }
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          minHeight="calc(100vh - 120px)"
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
          sx={{ background: "transparent" }}
        />
      ) : (
        <Grid
          container
          sx={{ height: "100%", width: "100%" }}
          className="Credits-Page-Container"
        >
          <Grid container item spacing={5}>
            {/* {START: Up Side} */}
            <Grid item xs={12} lg={12}>
              <Grid container spacing={7}>
                <Grid item sm={6} lg={12} xl={6}>
                  <Grid container spacing={3}>
                    {/* START: Credits Used */}
                    <Grid item xs={6}>
                      <CreditConsumed
                        vertical={true}
                        action_text="Request Credits"
                        action_link={urls.credit_requests}
                      />
                    </Grid>
                    {/* END: Credits Used */}
                    {/* START: Credit Request */}
                    <Grid item xs={6}>
                      <Requests data={creditRequestSummary || []} />
                    </Grid>
                    {/* END: Credit Request */}
                  </Grid>
                </Grid>

                {/* {START: Graph} */}
                <Grid item sm={6} lg={12} xl={6}>
                  <Paper
                    elevation={0}
                    style={{
                      height: "100%",
                      marginBottom: "-90px",
                    }}
                  >
                    {!fetchingChartData && (
                      <Chart
                        data={chartData.credits}
                        xKey="date"
                        yKey="credits"
                      />
                    )}
                  </Paper>
                </Grid>
                {/* END: Graph */}
              </Grid>
            </Grid>
            {/* END: Up Side */}
            {/* START: Down Side */}
            <Grid item xs={12} pb={2}>
              <Paper elevation={0}>
                <TableComponent
                  cols={cols}
                  rows={{
                    count: data?.results?.length || 0,
                    results: data.results,
                  }}
                  loading={fetchingCredits}
                  rowLink={{
                    linkKey: "id",
                    linkTo: "/credit",
                  }}
                  enableViewAll={{
                    viewAll: true,
                    maxResults: 5,
                    viewLink: "/credits/requests/",
                    title: "View All",
                  }}
                  tableRowStyles={{
                    backgroundColor: "#fff",
                    transition: "background-color .2s ease",
                    ":hover": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Paper>
            </Grid>
            {/* END: Down Side */}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AuthHOC(Credits);
