// libraries
import { useSnackbar } from "notistack";
//react
import { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

// material UI components
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

//internal
import UserDetail from "../../components/user_details/UserDetail";
import AuthHOC from "../../hoc/AuthHOC";
import ComponentLoader from "../../components/loader/ComponentLoader";
import TableComponent from "../../components/Table";
import CustomPagination from "../../components/CustomPagination";
import urls from "../../utils/urls.json";
import { axiosConfig } from "../../utils/helpers";
import { httpErrorHandler } from "../../utils/helpers";
import ServicesInfo from "../../components/services/ServiceInfo";
//hooks
import useAxios from "../../hooks/useAxios";
//utils
import { callInfoTableCols, transactionHistoryTableCols } from "./utils";

const decorateStatus = ["Status", "Transaction Activity"];

function CallDetails({ user }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 10;
  const [txnHistory, setTxnHistory] = useState(null);
  //axiosInstance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoading(true);
      setError(null);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/calls/${location.state.id}`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setData(response.data);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
      setLoading(false);
    })();
    return () => abortController.abort();
  }, [reload, closeSnackbar, enqueueSnackbar]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/txn-history/supervisor`,
          params: {
            page: page,
            page_size: PAGE_SIZE,
            call_id: location?.state?.id ? location?.state?.id : null,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setTxnHistory(response.data);
      } catch (error) {
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    })();
    return () => abortController.abort();
  }, [reload, closeSnackbar, enqueueSnackbar, page, location]);

  let breadCrumbs = [];
  if (location.state) {
    breadCrumbs = [
      <Link key="1" to={urls.call_histories} style={{ color: "#969696" }}>
        Call History
      </Link>,
      <Typography key="2" color="#969696">
        Details
      </Typography>,
    ];
  }

  const userData = useMemo(
    () => [
      [
        {
          "First Name": () => {
            return location.state.user.first_name;
          },
        },
      ],
      [
        {
          "Last Name": () => {
            return location.state.user.last_name;
          },
        },
      ],

      [
        {
          Email: location.state.user.email,
        },
        {
          typographyStyle: { valueStyle: {} },
        },
      ],
      [
        {
          "Added On": location.state.user.date_joined,
        },
      ],
    ],
    [location.state]
  );

  return (
    <>
      {loading || error ? (
        <ComponentLoader
          loading={loading}
          error={error}
          minHeight="calc(100vh - 120px)"
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
          sx={{ background: "transparent" }}
        />
      ) : location.state ? (
        <Grid container spacing={3}>
          {/* START: Breadcrumb */}
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadCrumbs}
            </Breadcrumbs>
          </Grid>
          {/* END: Breadcrumb */}
          {/* START: Body Row */}
          <Grid item xs={12}>
            <TableComponent
              rows={{ count: 1, results: [location.state] }}
              cols={callInfoTableCols}
              sx={{
                boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
                borderRadius: "4px",
              }}
            />
          </Grid>
          {/* START: User Details */}
          <Grid item xs={12}>
            <UserDetail title="User Detail" data={userData} disableAction />
          </Grid>
          {/* END: User Details */}

          {/* START: Service Details */}
          <Grid item xs={12}>
            <ServicesInfo servicesData={data} />
          </Grid>
          {/* END: Service Details */}
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ mb: "4rem" }}>
              <Stack
                sx={{ width: "100%", p: "24px 32px 8px 32px" }}
                direction="column"
                spacing={2}
              >
                <Stack direction="column" spacing={1} sx={{}}>
                  <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
                    Transaction History
                  </Typography>
                  <Typography fontSize="16px" fontWeight="400" color="#898989">
                    View your transaction history about this call
                  </Typography>
                </Stack>
              </Stack>
              <TableComponent
                sx={{ mb: "0rem", boxShadow: "none" }}
                cols={transactionHistoryTableCols}
                rows={txnHistory}
                loading={loading}
                decorateStatus={decorateStatus}
                statusColorMode="bg"
                // enableViewAll={{
                //   title: "View All",
                //   viewAll: true,
                //   viewLink: "/montages",
                //   maxResults: 2,
                // }}
                // rowLink={{
                //   linkKey: "id",
                //   linkTo: "/txn",
                // }}
                paginate={
                  <CustomPagination
                    disabled={loading}
                    last_page_no={Math.ceil(
                      txnHistory?.count
                        ? txnHistory?.count / PAGE_SIZE
                        : 1 / PAGE_SIZE
                    )}
                    limit={PAGE_SIZE}
                    handlePaginationChange={(_, value) => {
                      setPage(value);
                    }}
                    sx={{ mt: "2rem" }}
                  />
                }
              />
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}

export default AuthHOC(CallDetails);
