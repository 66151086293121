//utils
import CustomServicesCell from "../../components/custom_service";
import { formatDateTimeFns } from "../../utils/helpers";

export const callInfoTableCols = [
  {
    name: "USERNAME",
    accessor: "user",
    getAccessor: (el) => el.first_name + " " + el.last_name,
  },
  {
    name: "DATE",
    accessor: "root",
    getAccessor: (el) => formatDateTimeFns(el?.created_on),
  },
  {
    name: "CREDIT USED",
    accessor: "credit_used",
    getAccessor: (el) => (el ? parseFloat(el).toFixed(2) : ""),
  },

  {
    name: "TOTAL TIME",
    accessor: "root",
    getAccessor: (el) => {
      return el?.total_time
        ? `${el?.total_time?.split(":")[1]}:${
            el?.total_time?.split(":")[2]?.split(".")[0]
          } Mins`
        : "-";
    },
  },

  {
    name: "SERVICE USED",
    component: (data) => {
      return <CustomServicesCell sx={{ margin: "auto" }} data={data?.data} />;
    },
  },
];

export const transactionHistoryTableCols = [
  {
    name: "Credits Used",
    accessor: "root",
    getAccessor: (el) => {
      return el?.message.action === "credit.deducted"
        ? parseFloat(el?.message?.net_credits_required).toFixed(2)
        : parseFloat(el?.message?.net_credits_refunded).toFixed(2);
    },
    linkTo: null,
  },
  {
    name: "Call Name",
    accessor: "root",
    getAccessor: (el) => {
      return el?.call?.file_name;
    },
    linkTo: null,
  },
  {
    name: "Service Used",
    getAccessor: (row) => row?.call,
    component: (data) => {
      return <CustomServicesCell sx={{ margin: "auto" }} data={data?.data} />;
    },
  },
  {
    name: "Time",
    accessor: "root",
    getAccessor: (el) => formatDateTimeFns(el?.created_on, "p"),
  },
  {
    name: "Date",
    accessor: "created_on",

    linkTo: null,
  },
  {
    name: "Transaction Activity",
    accessor: "root",
    getAccessor: (el) => {
      return el.message.action === "credit.deducted"
        ? "Deducted"
        : el.message.action === "credit.refund"
        ? "Refunded"
        : el.message.action;
    },
    linkTo: null,
  },
];
