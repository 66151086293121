import { createTheme } from "@mui/material/styles";
export const light = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#022539",
    },
    secondary: {
      main: "#2CA9E3",
    },
    info: {
      main: "#BDE7D9",
    },
    warning: {
      main: "#FB991A",
    },
    success: {
      main: "#47D7A2",
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
  },
});
