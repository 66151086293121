import { Link, useLocation } from "react-router-dom";

// material UI components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// material UI icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// custom components
import AuthHOC from "../hoc/AuthHOC";

// utils
import { formatDateTimeFns } from "../utils/helpers";
import urls from "../utils/urls.json";
import { Stack } from "@mui/material";
import TableComponent from "../components/Table";

const cols = [
  {
    name: "CREDITS ADDED",
    accessor: "added",
  },
  {
    name: "CREDITS USED",
    accessor: "used",
  },
  {
    name: "CREDITS AVAILABLE",
    accessor: "available",
  },

  {
    name: "ISSUED ON",
    accessor: "root",
    getAccessor: (row) =>
      row?.added_on ? formatDateTimeFns(row?.added_on) : "-",
  },
  {
    name: "VALID TILL",

    accessor: "root",
    getAccessor: (row) =>
      row?.validity ? formatDateTimeFns(row?.validity) : "-",
  },
  {
    name: "STATUS",
    accessor: "active",
  },
];

function CreditDetails({ user }) {
  const location = useLocation();
  // console.log(location.state);

  const breadcrumbs = [
    <Link key="1" to={urls.credits}>
      Credits
    </Link>,
    <Typography key="2" color="text.primary">
      Credit Details
    </Typography>,
  ];

  return (
    <>
      <Grid container spacing={3}>
        {/* START: Breadcrumb */}
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>{" "}
        {/* END: Breadcrumb */}
        {/* START: Body Row */}
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <Toolbar>
                <Typography
                  component="div"
                  style={{
                    margin: 0,
                    padding: "15px 0px 20px 0px",
                    color: "#022539",
                  }}
                >
                  <Box fontSize="16px" fontWeight={700} fontStyle="normal">
                    Credit Info
                  </Box>
                </Typography>
              </Toolbar>

              <TableComponent
                cols={cols}
                rows={{ count: 1, results: [location.state] }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ padding: "24px" }}>
            <Stack spacing={5}>
              <Stack
                spacing={1}
                direction="column"
                borderBottom="1px solid #EAEAEA"
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "#0F0F0F",
                  }}
                >
                  Payment Details
                </Typography>
              </Stack>

              <Stack direction="row" spacing={5}>
                <Stack direction="column" spacing={5}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Total Credit Reqested
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Price per Credit
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Total Price{" "}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Purpose
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#898989",
                    }}
                  >
                    Mode of Payment{" "}
                  </Typography>
                </Stack>

                <Stack direction="column" spacing={5}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    {location.state.added
                      ? parseFloat(location.state.added).toFixed(0)
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    $
                    {location.state?.payment?.price
                      ? parseFloat(
                          location.state?.payment?.price /
                            100 /
                            location.state?.added
                        ).toFixed(2)
                      : "-"}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    $
                    {location.state?.payment?.price
                      ? parseFloat(location.state.payment.price / 100).toFixed(
                          0
                        )
                      : "-"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    {location.state.purpose || "N/A"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                    }}
                  >
                    {location.state.payment.mode === "STRIPE" && "Stripe"}
                    {location.state.payment.mode === "CHEQUE" && "Cheque"}
                    {location.state.payment.mode === "DEMO" && "Demo"}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthHOC(CreditDetails);
